<template>
  <div>
    <el-form
      label-position="left"
      label-width="220px"
      style="width: 700px"
      size="mini"
    >
      <el-form-item label="Скрытый">
        <el-switch v-model="localWidgetSettings.disabled"></el-switch>
      </el-form-item>
      <el-form-item label="Размер тени">
        <el-slider v-model="localWidgetSettings.shadowSize" :min="0" :max="30" show-input input-size="mini"></el-slider>
      </el-form-item>
      <el-form-item label="Цвет и интенсивность тени">
        <el-color-picker v-model="localWidgetSettings.shadowColor" show-alpha></el-color-picker>
      </el-form-item>
      <el-form-item label="Размер границы">
        <el-slider v-model="localWidgetSettings.borderSize" :min="0" :max="10" show-input input-size="mini"></el-slider>
      </el-form-item>
      <el-form-item label="Цвет и интенсивность границы">
        <el-color-picker v-model="localWidgetSettings.borderColor" show-alpha></el-color-picker>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

export default {
  name: 'WidgetSettingsContainerForm',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    localWidgetSettings: {
      get() {return this.value},
      set(value) {this.$emit('input', value)}
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>