<template>
  <div>
    <cards-page-filters
      v-model="filters"
      :filter-deleted="filterDeleted"
      @change="loadCards"
    >
    </cards-page-filters>


    <el-table
      ref="cardsTable"
      size=""
      style="width: 100%"
      :data="cards"
      empty-text="Нет карт"
      v-loading="loading"
      :row-key="(row) => row.id"
      @selection-change="$emit('selection-changed', $event)"
      @sort-change="sortChanged($event, loadCards)"
    >
      <el-table-column v-if="selectable" type="selection" reserve-selection width="55"></el-table-column>

      <component
        v-for="(columnConfig, index) in columnsConfigs"
        v-if="isToShowColumn(index)"
        :is="columnConfig.manualColumnTemplate ? columnConfig.template : baseTableColumn"
        :key="index"
        :column-config="columnConfig"
        :sortable="setIsSortable(columnConfig.prop)"
        :filterParams="getFormatQueryParams()"
        @delete-card="deleteCard"
        @load-cards="loadCards"
      >
      </component>
    </el-table>


    <paginator
      :paginator.sync="paginator"
      use-router-query-param
      @page-changed="loadCards"
    ></paginator>
  </div>
</template>

<script>


import CardsPageFilters from "@/components/cards/CardsPageFilters";
import Paginator from "@/components/Paginator";
import {paginator} from "@/mixins/paginator";
import {sorter} from "@/mixins/sorter";
import Vue from "vue";
import axios from "axios";


export default {
  name: "cards-component",
  components: {Paginator, CardsPageFilters},
  mixins: [paginator, sorter],

  props: {
    filterDeleted: {type: Boolean, default: false},
    hideLastColumn: {type: Boolean, default: false},
    selectable: {type: Boolean, default: false},
  },
  computed: {
    baseTableColumn() {
      return () => import(`@/components/tableColumnTemplates/BaseTableColumn`);
    },
  },
  watch: {},
  data() {
    return {
      loading: false,
      runningRequests: [],

      cards: [],
      filters: {},

      columnsConfigs: [],
    }
  },
  beforeMount() {
    this.paginator.perPage = this.$companyConfiguration.card.getCardsPageDefaultPerPage();

    this.columnsConfigs = this.$companyConfiguration.helpers.getTableColumnsArrayFromConfig(
      this.$companyConfiguration.card.getCardsPageColumns()
    );
  },
  methods: {
    isToShowColumn(index) { // Пока что есть параметр для скрытия последней колонки (action)
      if (index !== this.columnsConfigs.length - 1) {
        return true;
      }
      return !this.hideLastColumn;
    },
    loadCards() {

      this.runningRequests.forEach(r => {
        r.cancel();
      })
      this.runningRequests = [];
      const request = axios.CancelToken.source();
      this.runningRequests.push(request);

      this.loading = true;
      this.$api.card.search({
        ...this.filters,
        deleted: this.filterDeleted ? 1 : null,
        expand: this.$companyConfiguration.card.getCardsPageSearchExpand().join(),

        [this.sorter.param]: this.sorter.sort,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      }, {cancelToken: request.token})
        .then(({data, paginator, sorter}) => {
          this.cards = data.cards;
          this.paginatorFill(paginator);
          this.sorterFill(sorter);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    deleteCard(card) {
      this.loading = true;
      this.$api.card.delete({
        id: card.id
      })
        .then(data => {
          this.$notify.success({
            title: 'Удалено',
            message: 'Карта успешно удалена'
          });
        })
        .finally(() => {
          this.loadCards();
        })
    },

    format(value, filter) {
      return Vue.filter(filter)(value);
    },
    getFormatQueryParams() {
      if (!this.$companyConfiguration.card.isCardNextAndPreviousButtons()) {
        return {};
      }

      const formattedParams = {};
      const params = {
        ...this.filters,
        [this.sorter.param]: this.sorter.sort,
      };

      Object.keys(params).forEach((key) => {
        const value = params[key];

        if (value !== null && value !== undefined && (Array.isArray(value) ? value.length > 0 : value !== '')) {
          if (Array.isArray(value)) {
            formattedParams[`${key}[]`] = value;
          } else {
            formattedParams[key] = value;
          }
        }
      });

      return formattedParams;
    },
  }
}
</script>


<style lang="scss">

</style>