<template>

  <div class="selected-field-item">
    <div class="selected-field-item-relation">{{ searchField.relationName }}</div>
    <div class="selected-field-item-name">{{ searchField.name }}</div>
    <div class="selected-field-item-name">
      <el-select
        :disabled="disabled"
        v-model="searchField.condition"
        placeholder="Выберите"
      >
        <el-option value="=" label="Равно"></el-option>
        <el-option value="<>" label="Не равно"></el-option>
        <el-option value=">=" label="Больше или равно"></el-option>
        <el-option value="<=" label="Меньше или равно"></el-option>
        <el-option value="like" label="Содержит"></el-option>
        <el-option value="not like" label="Не содержит"></el-option>
        <el-option value="is_not_null" label="Не пусто"></el-option>
        <el-option value="is_null" label="Не задано"></el-option>
      </el-select>
    </div>
    <div v-if="searchField.condition !== 'is_null' && searchField.condition !== 'is_not_null'">
      <div class="selected-field-item-name" v-if="searchField.enum">
        <el-select
          :disabled="disabled"
          v-model="searchField.search"
          placeholder="Выберите"
        >
          <el-option
            v-for="(optionName, optionValue) in searchField.enum"
            :key="optionValue"
            :label="optionName"
            :value="optionValue">
          </el-option>
        </el-select>
      </div>
      <div v-else class="selected-field-item-name" style="display: flex; justify-content: space-between">
        <el-input
          :disabled="disabled"
          v-model="searchField.search"
        ></el-input>
        <el-tooltip
          content="Вставить переменную"
          placement="top"
          :open-delay="100"
        >
          <el-button
            style="padding: 0; margin-left: 10px"
            size="mini"
            type="text"
            icon="fas fa-superscript"
            @click="showVariableForm=true"
          >
          </el-button>
        </el-tooltip>
      </div>
    </div>

    <el-dialog
      title="Вставка переменной"
      append-to-body
      :visible.sync="showVariableForm"
      width="400px"
      :close-on-click-modal="false"
    >
      <div
        v-for="(variableDesc, variableName) in variables"
        :key="variableName"
      >
        <el-button
          style="width: 100%; margin-bottom: 5px"
          size="mini"
          @click="searchField.search += variableName; showVariableForm=false;"
        >
          {{variableDesc}}
        </el-button>
      </div>


      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="showVariableForm=false">Закрыть</el-button>
      </div>
    </el-dialog>

  </div>


</template>

<script>

import ReportResultModal from "@/components/report/builder/ReportResultModal.vue";
import Builder from "@/components/report/builder/Builder.vue";

export default {
  name: "BuilderSelectedFieldItem",
  components: {Builder, ReportResultModal},
  props: {
    searchField: Object,
    disabled: {type: Boolean, default: false}
  },
  computed: {},

  data() {
    return {
      variables: {
        '{{current_user_id}}': 'ID текущего пользователя',
        '{{current_year}}': 'Номер текущего года',
        '{{previous_year}}': 'Номер предыдущего года',
        '{{current_month}}': 'Номер текущего месяца',
        '{{previous_month}}': 'Номер предыдущего месяца',
      },
      showVariableForm: false,
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
@import "@/assets/css/project-variables";


.selected-field-item {
  cursor: move;
  text-align: center;
  border: 1px solid $--color-primary;
  margin: 5px;
  background-color: white;
}

.selected-field-item-relation {
  padding: 10px;
  background-color: $--color-primary;
}

.selected-field-item-name {
  padding: 10px;
}

</style>