export default {
    "scales": {
        "configurable": {
            "types": {
                "quality": {
                    "showName": "Качественная",
                    "scopes_template": "components/configurableScales/settings/quality/QualityScopes"
                },
                "quantity": {
                    "showName": "Количественная",
                    "scopes_template": "components/configurableScales/settings/quantity/QuantityScopes"
                },
                "quantity_formula": {
                    "showName": "Количественная формула",
                    "scopes_template": "components/configurableScales/settings/quantity_formula/QuantityFormulaScopes"
                }
            }
        }
    }
}