<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>


<script>
import { mapGetters } from 'vuex';
import DefaultLayout from "./layouts/DefaultLayout";
import EmptyLayout from "./layouts/EmptyLayout";
import {RefreshClientOnUpdateMixin} from "@/mixins/RefreshClientOnUpdateMixin";
import VueYandexMetrika from 'vue-yandex-metrika'
import Vue from 'vue'

export default {
  name:"app",
  mixins: [RefreshClientOnUpdateMixin],
  components: {DefaultLayout, EmptyLayout},
  computed: {
    ...mapGetters([ 'user' ]),
    ...mapGetters('settings', {
      settings: 'settings',
    }),
    layout() {
      return this.$route.meta.layout || 'default-layout'
    }
  },
  watch: {
    settings(){
      if( this.settings.yandex_metrika_id ){
        this.addYandexMetrika();
      }
    }
  },

  mounted() {
    this.initAppVersionCheck();
  },

  methods: {
    addYandexMetrika() {
      let options = {clickmap:true, trackLinks:true, accurateTrackBounce:true};
      if( this.settings.yandex_metrika_options ){
        try {
          options = JSON.parse(this.settings.yandex_metrika_options);
        } catch (e) {
          console.error('Yandex Metrika Options is not valid JSON');
        }
      }
      new VueYandexMetrika(Vue,{
        id: this.settings.yandex_metrika_id,
        router: this.$router,
        env: 'production',
        debug: false,
        options: options,
      });
    },
  }

}
</script>

<style lang="scss">
//@import url("../node_modules/@fortawesome/fontawesome-free/css/all.css");
@import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
@import "assets/css/fonts.css";
@import "assets/css/style.scss";
</style>
