import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        getHomepage(payload){
            return requestSender('get', 'home-dashboard/get-homepage', payload);
        },
        saveHomepageSettings(payload){
            return requestSender('post', 'home-dashboard/save-homepage-settings', payload);
        },
        saveStandardDashboards(payload){
            return requestSender('post', 'home-dashboard/save-standard-dashboards', payload);
        },

        cloneDashboard(payload){
            return requestSender('post', 'home-dashboard/clone-dashboard', payload);
        },

        getDashboardWidgets(payload){
            return requestSender('get', 'home-dashboard/get-dashboard-widgets', payload);
        },
        saveUserWidget(payload){
            return requestSender('post', 'home-dashboard/save-user-widget', payload);
        },
        getWidgetResult(payload){
            return requestSender('get', 'home-dashboard/get-widget-result', payload);
        },
        deleteUserWidget(payload){
            return requestSender('post', 'home-dashboard/delete-user-widget', payload);
        },

    }
}