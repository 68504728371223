<template>
  <el-dialog
    class="widget-settings-dialog"
    :title="'Настройки виджета'"
    append-to-body
    :close-on-click-modal="false"
    :visible.sync="show"
    :width="widget.modalWidth || '50%'"
  >
    <div v-if="show">


      <el-tabs type="card" value="widgetSettings">
        <el-tab-pane label="Настройки блока">
          <WidgetSettingsContainerForm v-model="localWidgetSettings"></WidgetSettingsContainerForm>
        </el-tab-pane>
        <el-tab-pane label="Настройки заголовка">
          <WidgetSettingsHeaderForm v-model="localWidgetSettings"></WidgetSettingsHeaderForm>
        </el-tab-pane>

        <el-tab-pane name="widgetSettings" :label="widgetConfig.tabName || 'Настройки виджета'">
          <component
            v-model="localWidgetData"
            :additional-tabs.sync="additionalTabs"
            :is="widget.settingsTemplate"
            ref="widgetSettingsComponent"
          ></component>
        </el-tab-pane>


        <el-tab-pane
          v-for="additionalTab in additionalTabs"
          :key="additionalTab.name"
          :label="additionalTab.label"
          :name="additionalTab.name"
        >
          <component
            :is="additionalTab.component"
            v-model="localWidgetData"
          ></component>
        </el-tab-pane>

      </el-tabs>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="show = false;">Закрыть</el-button>
      <el-button type="primary" size="small" @click="saveSettings">Сохранить</el-button>
    </span>
  </el-dialog>
</template>

<script>

import WidgetSettingsContainerForm from "@/components/home/dashboard/widgetSettings/WidgetSettingsContainerForm.vue";
import WidgetSettingsHeaderForm from "@/components/home/dashboard/widgetSettings/WidgetSettingsHeaderForm.vue";

export default {
  name: 'WidgetSettingsDialog',
  components: {WidgetSettingsHeaderForm, WidgetSettingsContainerForm},
  props: {},
  data() {
    return {
      show: false,
      additionalTabs: [],

      widgetConfig: {},

      widget: {},
      localWidgetSettings: {},
      localWidgetData: {},

    }
  },

  methods: {
    async open(widget){
      this.additionalTabs = [];
      this.widgetConfig = this.$companyConfiguration.home.getWidgetConfig(widget.type);

      this.widget = widget;
      this.localWidgetSettings = {...widget.settings};
      this.localWidgetData = {...widget.data};

      await widget.loadSettingsTemplate();

      this.show = true;
    },


    saveSettings() {
      this.widget.settings = this.localWidgetSettings;
      this.widget.data = this.localWidgetData;

      this.widget.save()
        .then((data) => {
          this.$emit('saved', this.widget);
          this.show = false;
        })

    },


  }
}
</script>

<style lang="scss">
.widget-settings-dialog .el-tabs__content{
  overflow: unset;
}

.el-tabs__new-tab{
  width: 100px;

  &:after{
    content: 'Добавить';
  }
}

</style>