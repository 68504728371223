<template>
  <div class="homepage" v-loading="loading">
    <div class="homepage-toolbox">
      <el-button
        v-if="editMode && $canAndRulesPass(user, $permissions.HOME_DASHBOARD.STANDARD_DASHBOARD_MANAGE)"
        style="margin-right: 30px"
        type="text"
        icon="fas fa-wrench"
        @click="$refs.homepageStandardDashboardsSettingsDialog.open(standardDashboards);"
      >
        Настройки стандартных дашбордов
      </el-button>

      <el-button
        v-if="editMode"
        style="margin-right: 30px"
        type="text"
        icon="fas fa-wrench"
        @click="$refs.homepageSettingsDialog.open(homepage, dashboards, standardDashboards);"
      >
        Настройки
      </el-button>

      <el-dropdown
        v-if="editMode && $canAndRulesPass(currentDashboard, $permissions.HOME_DASHBOARD.WIDGET_MANAGE)"
        style="margin-right: 30px"
        trigger="click"
        @command="addWidget"
      >
        <el-button type="text" icon="fas fa-plus">Добавить виджет</el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(widgetConfig, widgetKey) in $companyConfiguration.home.getWidgets()"
            :key="widgetKey"
            :command="widgetKey"
          >
            {{widgetConfig.showName}}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <el-button
        type="text"
        :icon="'fas fa-' + (editMode ? 'check' : 'cog')"
        @click="editMode=!editMode"
      >
        <span v-if="editMode">Готово</span>
      </el-button>
    </div>


    <el-tabs
      v-if="homepage"
      v-model="currentTab"
      :type="homepage.settings.tabsType"
      :tab-position="homepage.settings.tabsPosition"
      class="homepage-tabs"
      :class="{
        'hidden-header': !homepage.settings.tabsVisible,
      }"
    >
      <el-tab-pane
        v-for="dashboard in [...dashboards, ...standardDashboards]"
        :name="dashboard.id.toString()"
        lazy
        :key="dashboard.id"
      >
        <span slot="label">
          <i
            v-if="!dashboard.homepage_id"
            style="margin-right: 5px"
            class="fas fa-lock"
          ></i>
          {{dashboard.settings.name}}
          <el-popconfirm
            v-if="editMode && $canAndRulesPass(user, $permissions.HOME_DASHBOARD.DASHBOARD_MANAGE)"
            confirm-button-text='Да'
            cancel-button-text='нет'
            icon="el-icon-question"
            title="Клонировать дашборд?"
            @confirm="cloneDashboard(dashboard)"
          >
            <el-button
              slot="reference"
              icon="fas fa-copy"
              type="text"
            ></el-button>
          </el-popconfirm>
        </span>
        <HomepageDashboard
          v-if="dashboard.id===parseInt(currentTab)"
          :dashboard="dashboard"
          :dashboards="dashboards"
          :edit-mode="editMode"
          :ref="'dashboard_'+dashboard.id"
        ></HomepageDashboard>
      </el-tab-pane>
    </el-tabs>


    <HomepageSettingsDialog
      ref="homepageSettingsDialog"
      @saved="loadHomepage"
    />

    <HomepageStandardDashboardsSettingsDialog
      ref="homepageStandardDashboardsSettingsDialog"
      @saved="loadHomepage"
    />


  </div>
</template>

<script>


import HomepageDashboard from "@/components/home/dashboard/HomepageDashboard.vue";
import HomepageSettingsDialog from "@/components/home/dashboard/HomepageSettingsDialog.vue";
import HomepageStandardDashboardsSettingsDialog
  from "@/components/home/dashboard/HomepageStardardDashboardsSettingsDialog.vue";
import {mapGetters} from "vuex";

export default {
  name: 'Homepage',
  components: {HomepageStandardDashboardsSettingsDialog, HomepageSettingsDialog, HomepageDashboard},
  computed: {
    ...mapGetters([ 'user' ]),
    currentDashboard(){
      return this.dashboards.concat(this.standardDashboards).find(d => d.id === +this.currentTab);
    },
  },
  data() {
    return {
      loading: false,
      editMode: false,
      currentTab: null,

      homepage: null,
      dashboards: [],
      standardDashboards: [],
    };
  },
  mounted() {
    this.loadHomepage();
  },
  methods: {
    loadHomepage(){
      this.loading = true;
      this.$api.homeDashboard.getHomepage({
        expand: 'permissions'
      })
        .then((data) => {
          this.homepage = data.homepage;
          this.dashboards = data.dashboards;
          this.standardDashboards = data.standard_dashboards;

          this.currentTab = this.findCurrentTab();
        })
        .finally(()=>{
          this.loading = false;
        })
    },

    findCurrentTab(){
      if( this.homepage.default_dashboard_id ){
        return this.homepage.default_dashboard_id.toString();
      }

      if( this.standardDashboards.length ){
        return this.standardDashboards[0]?.id?.toString();
      }

      return this.dashboards[0]?.id?.toString();
    },

    cloneDashboard(dashboard){
      this.$api.homeDashboard.cloneDashboard({
        dashboard_id: dashboard.id,
      })
        .finally(() => {
          this.loadHomepage();
        })
    },

    addWidget(widgetType){
      if( !this.currentTab ){
        return;
      }

      this.$refs['dashboard_'+this.currentTab][0].addWidget(widgetType);
    }


  }
};
</script>


<style lang="scss">
@import "@/assets/css/project-variables";
.homepage{
  position: relative;

  &-toolbox{
    position: absolute;
    top: -28px;
    right: 0;
    z-index: 2;
  }

  &-tabs{
    &.hidden-header .el-tabs__header{
      display: none;
    }

    .el-tabs__content{
      overflow: inherit;
    }
  }

}

</style>`