<template>
    <span>
        <div class="builder-container-row-name">
            <el-checkbox-button
              :disabled="loading"
              v-model="element.checked"
              @change="nodeChecked"
            >
                <el-row type="flex" justify="space-between">
                    <div v-if="element.relationName">{{ element.relationName }}</div>
                    <div v-else>{{ element.name }}</div>
                    <div style="margin-left: 5px;">
                        <i class="el-icon-loading" v-if="loading"></i>
                        <i class="fas fa-chevron-right" v-else></i>
                    </div>
                </el-row>


            </el-checkbox-button>

            <div
              v-if="element.checked"
              class="builder-container-row-fields"
            >
                <Container
                  behaviour="copy"
                  group-name="1"
                  drag-class="node-element-drag-ghost"
                  :get-child-payload="getDragElementPayload"
                >
                    <Draggable v-for="(field, fieldIndex) in element.fields" :key="fieldIndex">
                        <div
                          @contextmenu.prevent
                          @click.right.prevent="$emit('add-search-field-from-node', {node:element, field:field})"
                        >
                            <el-button
                              type="text"
                              size="mini"
                              icon="fas fa-arrows-alt"
                              @click="$emit('add-field-from-node', {node:element, field:field})"
                            >
                                {{ field.name }}
                            </el-button>
                        </div>
                    </Draggable>
                </Container>

            </div>


        </div>

    </span>

</template>

<script>
import BuilderColumn from "./BuilderColumn";
import {Container, Draggable} from 'vue-dndrop'

export default {
  name: "builder-node",
  components: {BuilderColumn, Container, Draggable},
  props: {
    element: {
      type: Object,
      default: {
        checked: false
      }
    },
    relationChain: Array,

    loadChildren: Function
  },
  computed: {},
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    nodeChecked(state) {
      this.element.checked = state;

      if (state) {
        this.loading = true;
        this.loadChildren(this.element, this.setChildren)
      } else {
        this.setChildren([]);
      }
      this.$forceUpdate();
      this.$emit('node-check-changed', this.element)
    },

    setChildren(children) {
      this.loading = false;
      children.forEach(child => {
        child.relationChain = [...this.relationChain, child.relation];
      })
      this.element.children = children;
      this.$emit('new-children')
    },


    getDragElementPayload(index) {
      return {
        node: this.element,
        field: this.element.fields[index]
      }
    },

    rightClicked(data){
      console.log(data);
      event.preventDefault();
    },
  }
}
</script>
