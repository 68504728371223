export default {
    "colors": [],
    "homepage_settings": {
        "tabsVisible": false,
        "tabsPosition": "top"
    },
    "widgets": {
        "button": {
            "showName": "Кнопка",
            "widgetFolder": "components/home/dashboard/widgets/ButtonWidget"
        },
        "text": {
            "showName": "Текст",
            "widgetFolder": "components/home/dashboard/widgets/TextWidget"
        },
        "vqb_table": {
            "showName": "Таблица",
            "widgetFolder": "components/home/dashboard/widgets/VQBTableWidget"
        },
        "vqb_cell": {
            "showName": "Ячейка",
            "widgetFolder": "components/home/dashboard/widgets/VQBCellWidget"
        },
        "vqb_chart": {
            "showName": "График",
            "widgetFolder": "components/home/dashboard/widgets/VQBChartWidget"
        }
    }
}