<template>
  <div>
    <el-form
      label-position="left"
      label-width="220px"
      style="width: 700px"
      size="mini"
    >
      <el-form-item label="Текст (пусто если не нужно)">
        <el-input v-model="localWidgetSettings.name" clearable></el-input>
      </el-form-item>
      <el-form-item label="Цвет текста">
        <el-color-picker v-model="localWidgetSettings.headerTextColor"></el-color-picker>
      </el-form-item>
      <el-form-item label="Размер текста">
        <el-slider v-model="localWidgetSettings.headerTextSize" :min="1" :max="80" show-input input-size="mini"></el-slider>
      </el-form-item>
      <el-form-item label="Расположение текста">
        <el-radio-group v-model="localWidgetSettings.headerTextAlign">
          <el-radio-button label="left"><i class="fas fa-align-left"></i></el-radio-button>
          <el-radio-button label="center"><i class="fas fa-align-center"></i></el-radio-button>
          <el-radio-button label="right"><i class="fas fa-align-right"></i></el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Padding">
        <el-slider v-model="localWidgetSettings.headerPadding" :min="1" :max="30" show-input input-size="mini"></el-slider>
      </el-form-item>
      <el-form-item label="Цвет background">
        <el-color-picker v-model="localWidgetSettings.headerBackgroundColor"></el-color-picker>
      </el-form-item>
      <el-form-item label="Размер нижней границы">
        <el-slider v-model="localWidgetSettings.headerBottomBorderSize" :min="0" :max="10" show-input input-size="mini"></el-slider>
      </el-form-item>
      <el-form-item label="Цвет нижней границы">
        <el-color-picker v-model="localWidgetSettings.headerBottomBorderColor" show-alpha></el-color-picker>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

export default {
  name: 'WidgetSettingsHeaderForm',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    localWidgetSettings: {
      get() {return this.value},
      set(value) {this.$emit('input', value)}
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>