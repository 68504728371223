<template>
  <div v-if="$canAndRulesPass(user, $permissions.HOME_DASHBOARD.SEE_IN_MENU)">
    <Homepage></Homepage>
  </div>
</template>

<script>
import Homepage from "@/components/home/dashboard/Homepage.vue";
import {mapGetters} from "vuex";

export default {
  name: 'HomeDashboard',
  components: {
    Homepage,
  },
  computed:{
    ...mapGetters([ 'user' ])
  },
  data() {
    return {};
  },
  methods: {

  }
};
</script>
