import ApiPlugin from '@/api/base/index'
import CompanyConfiguration from '@/plugins/companyConfiguration'

export default class UserWidget {
  constructor(obj){

    this.renderKey = 1; // To force refresh of the widget

    this.id = null;
    this.dashboard_id = null;
    this.type = null;
    this.dimensions = {};
    this.settings = {};
    this.data = {};

    this.viewTemplate = null;
    this.settingsTemplate = null;

    if( obj ){
      Object.assign(this, obj);
    }

    //Should be reactive for grid-layout
    this.i = this.id;
    this.x = this.dimensions.x;
    this.y = this.dimensions.y;
    this.w = this.dimensions.w;
    this.h = this.dimensions.h;

  }

  setId(newId){
    this.id = newId;
    this.i = newId;
  }

  async loadViewTemplate() {
    const widgetConfig = CompanyConfiguration.configs.home.getWidgetConfig(this.type);
    try {
      const module = await import(`@/${widgetConfig.widgetFolder}/view.vue`)
      this.viewTemplate = module.default;
    } catch (error) {
      console.error(`Failed to load widget component: ${widgetConfig.widgetFolder}`, error)
      return null
    }
  }
  async loadSettingsTemplate() {
    const widgetConfig = CompanyConfiguration.configs.home.getWidgetConfig(this.type);
    try {
      const module = await import(`@/${widgetConfig.widgetFolder}/settings.vue`)
      this.settingsTemplate = module.default;
    } catch (error) {
      console.error(`Failed to load widget component: ${widgetConfig.widgetFolder}`, error)
      return null
    }
  }


  save() {
    this.dimensions.x = this.x;
    this.dimensions.y = this.y;
    this.dimensions.w = this.w;
    this.dimensions.h = this.h;

    return ApiPlugin.homeDashboard.saveUserWidget({
      id: this.id,
      dashboard_id: this.dashboard_id,
      type: this.type,
      dimensions: this.dimensions,
      settings: this.settings,
      data: this.data,
    })
      .catch(() => {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Не смог сохранить виджет'
        });
      })
  }


}