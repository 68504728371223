var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"dashboard"},[_c('grid-layout',{staticClass:"dashboard-grid-layout",class:{'edit-mode': _vm.editMode},attrs:{"layout":_vm.userWidgets,"col-num":_vm.colNum,"row-height":30,"is-draggable":_vm.editMode && _vm.$canAndRulesPass(_vm.dashboard, _vm.$permissions.HOME_DASHBOARD.WIDGET_MANAGE),"is-resizable":_vm.editMode && _vm.$canAndRulesPass(_vm.dashboard, _vm.$permissions.HOME_DASHBOARD.WIDGET_MANAGE),"is-bounded":false,"vertical-compact":false,"use-css-transforms":true,"prevent-collision":true,"margin":[10,10]}},_vm._l((_vm.userWidgets),function(widget){return _c('grid-item',{key:widget.i,staticClass:"dashboard-grid-item",class:{
          'dashboard-grid-item-hovered': (_vm.currentHover===widget.i || _vm.currentDeleting===widget.i)
        },attrs:{"x":widget.x,"y":widget.y,"w":widget.w,"h":widget.h,"i":widget.i,"min-w":1,"min-h":1},on:{"resized":_vm.widgetResized,"moved":_vm.widgetMoved}},[(!widget.settings.disabled || _vm.editMode)?_c('div',{staticClass:"widget-container",style:({
            'box-shadow': '0 0 '+widget.settings.shadowSize+'px '+widget.settings.shadowColor,
            'border': widget.settings.borderSize+'px solid '+widget.settings.borderColor,
          }),on:{"mouseover":function($event){return _vm.widgetHovered(widget.i)},"mouseout":_vm.widgetUnhovered}},[(widget.settings.disabled)?_c('div',{staticClass:"widget-container-overlay"}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.editMode && _vm.$canAndRulesPass(_vm.dashboard, _vm.$permissions.HOME_DASHBOARD.WIDGET_MANAGE) && (_vm.currentHover===widget.i || _vm.currentDeleting===widget.i)),expression:"editMode && $canAndRulesPass(dashboard, $permissions.HOME_DASHBOARD.WIDGET_MANAGE) && (currentHover===widget.i || currentDeleting===widget.i)"}],staticClass:"widget-actions-container",style:({
              top: widget.y===0 ? 'unset' : '-40px',
              bottom: widget.y===0 ? '-44px' : 'unset',
              right: widget.x > _vm.colNum-8 ? '0' : 'unset',
            })},[_c('el-button',{attrs:{"size":"mini","icon":"fas fa-cog"},on:{"click":function($event){return _vm.openSettings(widget)}}},[_vm._v(" Настройки ")]),_c('el-tooltip',{staticStyle:{"margin-right":"10px"},attrs:{"content":"Клонировать","placement":"top","open-delay":500}},[_c('el-button',{attrs:{"size":"mini","icon":"fas fa-copy"},on:{"click":function($event){return _vm.cloneWidget(widget)}}})],1),_c('el-popover',{attrs:{"placement":"bottom","width":"250","trigger":"click"}},[_c('div',[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("Выберите дашборд для перемещения:")]),_vm._l((_vm.dashboards),function(dashboardToShow){return _c('div',{key:dashboardToShow.id,staticStyle:{"margin-bottom":"5px"}},[_c('el-button',{staticStyle:{"width":"100%","text-wrap":"auto"},attrs:{"disabled":dashboardToShow.id===_vm.dashboard.id,"size":"mini"},on:{"click":function($event){return _vm.moveWidgetToDashboardId(widget, dashboardToShow.id)}}},[_vm._v(" "+_vm._s(dashboardToShow.id===_vm.dashboard.id ? 'Текущий' : dashboardToShow.settings.name)+" ")])],1)})],2),_c('el-tooltip',{attrs:{"slot":"reference","content":"Переместить на дашборд","placement":"top","open-delay":500},slot:"reference"},[_c('el-button',{attrs:{"size":"mini","icon":"fas fa-share-square"}})],1)],1),_c('el-popconfirm',{attrs:{"confirm-button-text":"Да","cancel-button-text":"нет","icon":"el-icon-question","title":"Вы уверены, что хотите удалить виджет?"},on:{"confirm":function($event){return _vm.removeWidget(widget.i)}}},[_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"slot":"reference","type":"danger","size":"mini","icon":"fas fa-trash"},on:{"click":function($event){_vm.currentDeleting=widget.i}},slot:"reference"})],1)],1),(widget.settings.name)?_c('div',{staticClass:"widget-header",style:({
              'color': widget.settings.headerTextColor || 'black',
              'font-size': (widget.settings.headerTextSize || '16') + 'px',
              'text-align': widget.settings.headerTextAlign || 'center',
              'padding': widget.settings.headerPadding+'px',
              'background-color': widget.settings.headerBackgroundColor,
              'border-bottom': widget.settings.headerBottomBorderSize+'px solid '+widget.settings.headerBottomBorderColor,
            })},[_vm._v(" "+_vm._s(widget.settings.name)+" ")]):_vm._e(),_c('div',{staticClass:"widget-body"},[_c(widget.viewTemplate,{key:widget.renderKey,ref:'widget_'+widget.id,refInFor:true,tag:"component",attrs:{"edit-mode":_vm.editMode,"widget":widget,"settings":widget.settings,"data":widget.data}})],1)]):_vm._e()])}),1)],1),_c('WidgetSettingsDialog',{ref:"widgetSettingsModal",on:{"saved":_vm.widgetSaved}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }