import Vue from 'vue'

import ElementUI from 'element-ui';

import './assets/css/element-variables.scss';

import locale from 'element-ui/lib/locale/lang/ru-RU'
import App from './App.vue'

import vueDebounce from 'vue-debounce'

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'


import ApiPlugin from './plugins/api'
import ConstantsPlugin from './plugins/constants'
import CompanyConfiguration from './plugins/companyConfiguration'
import PermissionsPlugin from './plugins/permissions'


import router from './router'

import store from './store'

import wysiwyg from "vue-wysiwyg";
import "vue-wysiwyg/dist/vueWysiwyg.css"
import NumberFormat from "@/directives/NumberFormat";
import {NumberFilter} from "@/directives/NumberFormat";


Vue.config.productionTip = false

Vue.use(ApiPlugin);
Vue.use(ConstantsPlugin);
Vue.use(CompanyConfiguration);
Vue.use(ElementUI, {locale});
Vue.use(vueDebounce);
Vue.use(PermissionsPlugin);
Vue.use(wysiwyg, {hideModules: { "image": true, "table": true }}); // config is optional. more below


Vue.component('VueSlider', VueSlider)
Vue.directive('numberFormat', NumberFormat);
Vue.filter('numberFormat', NumberFilter)


function loadConfig() {
  return fetch('/config.json?t=' + new Date().getTime())
    .then(response => response.json())
    .then(config => {
      Vue.prototype.$config = config;
    });
}

loadConfig().then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
});
