<template>
  <div>
    <el-table
      :key="tableKey"
      v-loading="loading"
      ref="reportTable"
      id="reportTable"
      :data="reportData"
      style="width: 100%;"
      :cell-style="cellBodyStyle"
      :header-cell-style="cellHeaderStyle"
      :show-header="!hideHeader"
    >
      <el-table-column
        v-for="(column, columnIndex) in reportDataColumns"
        :key="columnIndex"
        :min-width="transformedColumnsWidth[columnIndex]"
      >
        <template slot="header"  slot-scope="scope">
          <div style="font-weight: bold">{{column.block}}</div>
          <div>{{column.field}}</div>
          <div v-if="column.aggregation">{{column.aggregation}}</div>
        </template>
        <template slot-scope="scope">
          {{ scope.row[column.column] }}
        </template>
      </el-table-column>
    </el-table>
    <paginator
      :paginator.sync="paginator"

      :hide-page-select="hidePageSelect"
      :hide-page-size="hidePageSize"

      @page-changed="loadReportData"
    ></paginator>
  </div>
</template>

<script>


import {paginator} from "@/mixins/paginator";
import Paginator from "@/components/Paginator.vue";

export default {
  name: "report-result",
  components: {Paginator},
  mixins: [paginator],

  props: {
    builderFields:{},
    builderRelations:{},
    builderSearch:{},

    hideHeader: {type: Boolean, default: false},
    hidePageSelect: {type: Boolean, default: false},
    hidePageSize: {type: Boolean, default: false},
    perPage: {type: Number},
    columnsWidth: {type: Array},
    fontSizeHeader: {type: Number, default: 14},
    fontSizeBody: {type: Number, default: 14},
    paddingHeader: {type: Number, default: 12},
    paddingBody: {type: Number, default: 12},
    colorHeader: {type: String, default: '#909399'},
    colorBody: {type: String, default: '#606266'},
  },
  computed: {
    transformedColumnsWidth(){
      let newWidthArray = [];
      for(let columnIndex=0; columnIndex<this.reportDataColumns.length; columnIndex++){
        if( !this.columnsWidth || !this.columnsWidth.length ){
          newWidthArray.push(0);
          continue;
        }
        if( columnIndex === 0 ){
          newWidthArray.push(this.columnsWidth[0]);
          continue;
        }
        if( columnIndex === this.columnsWidth.length ){
          newWidthArray.push(100 - this.columnsWidth[columnIndex-1]);
          continue;
        }
        newWidthArray.push(this.columnsWidth[columnIndex] - this.columnsWidth[columnIndex - 1]);
      }
      return newWidthArray;
    }
  },

  data() {
    return {
      tableKey: 1,
      loading: false,
      reportData: [],
      reportDataColumns: [],
    }
  },
  methods: {
    cellHeaderStyle(){
      return {
        'font-size': (this.fontSizeHeader || 14) + 'px',
        'padding': (this.paddingHeader || 12) + 'px',
        'color': this.colorHeader,
      };
    },
    cellBodyStyle(){
      return {
        'font-size': (this.fontSizeBody || 14) + 'px',
        'padding': (this.paddingBody || 12) + 'px',
        'color': this.colorBody,
      };
    },
    loadReportData(page=null){
      if(page){
        this.paginator.page = page;
      }
      document.getElementById('reportTable').scrollIntoView();
      this.loading = true;
      this.reportData = [];

      if( this.perPage ){
        this.paginator.perPage = this.perPage;
        if( this.paginator.perPageSizes.indexOf(this.perPage) === -1 ){
          this.paginator.perPageSizes.unshift(this.perPage);
        }
      }

      return this.$api.report.buildReport({
        selected_fields: this.builderFields,
        relations: this.builderRelations,
        search_fields: this.builderSearch,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
        .then(({data, paginator}) => {
          this.setReportData(data, paginator);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    setReportData(data, paginator){
      this.$set(this, 'reportData', data.data);
      this.$set(this, 'reportDataColumns', data.headers);
      this.tableKey++; // Update table
      if( paginator ){
        this.paginatorFill(paginator);
      }
    },

    calculateColumnWidth(columnIndex){
      if( !this.columnsWidth || !this.columnsWidth.length ){
        return 0;
      }
      if( columnIndex === 0 ){
        return this.columnsWidth[0];
      }
      if( columnIndex === this.columnsWidth.length ){
        return 100 - this.columnsWidth[columnIndex-1];
      }
      return this.columnsWidth[columnIndex] - this.columnsWidth[columnIndex - 1];
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/css/project-variables";

</style>