<template>
  <el-dialog
    class="homepage-settings-dialog"
    :title="'Настройки стандартных дашбордов'"
    append-to-body
    :close-on-click-modal="false"
    :visible.sync="show"
    :width="'50%'"
  >
    <div v-if="show">

      <el-form
        label-position="left"
        label-width="180px"
        style="width: 100%"
        size="mini"
      >
        <el-form-item label="Дашборды">
          <Container
            @drop="onDrop"
            drag-handle-selector=".column-drag-handle"
          >
            <Draggable
              v-for="(dashboard, dIndex) in dashboards"
              :key="dIndex"
            >
              <div style="display: flex; margin-bottom: 5px">
                <div class="column-drag-handle" style="margin-right: 10px; cursor: move">
                  <i class="fas fa-bars"></i>
                </div>
                <el-input size="mini" v-model="dashboard.settings.name"></el-input>
                <el-select
                  style="width: 700px; margin-left: 10px;"
                  v-model="dashboard.settings.roles"
                  multiple
                  clearable
                  placeholder="Для ролей"
                >
                  <el-option
                    v-for="role in roles"
                    :key="role.name"
                    :label="role.description"
                    :value="role.name"
                  ></el-option>
                </el-select>
                <el-popconfirm
                  confirm-button-text='Да'
                  cancel-button-text='нет'
                  icon="el-icon-question"
                  title="Вы уверены, что хотите удалить дашборд?"
                  @confirm="deleteDashboard(dIndex)"
                >
                  <el-button
                    slot="reference"
                    style="margin-left: 10px"
                    class="table-action-button"
                    type="default"
                    round
                    size="mini"
                    icon="far fa-trash-alt"
                  >
                  </el-button>
                </el-popconfirm>
              </div>

            </Draggable>
          </Container>

          <el-button
            size="mini"
            type="text"
            icon="fas fa-plus"
            @click="addDashboard"
          >Добавить дашборд</el-button>
        </el-form-item>

      </el-form>
    </div>


    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="show = false;">Закрыть</el-button>
      <el-button type="primary" size="small" @click="saveSettings">Сохранить</el-button>
    </span>
  </el-dialog>
</template>

<script>

import {Container, Draggable} from "vue-dndrop";
import {applyDragForDndrop} from "@/utils/helpers";

export default {
  name: 'HomepageStandardDashboardsSettingsDialog',
  components: {Container, Draggable},
  props: {},
  data() {
    return {
      loading: false,
      show: false,

      roles: [],
      dashboards: [],

    }
  },

  mounted() {
    this.$api.rbac.getRolesInSystem()
      .then(data => {
        this.roles = data.roles;
      })
  },

  methods: {
    async open(dashboards){
      this.dashboards = [...dashboards];
      this.show = true;
    },

    addDashboard(){
      this.dashboards.push({
        settings:{
          name: 'Новый дашборд',
        }
      })
    },

    deleteDashboard(dIndex){
      this.dashboards.splice(dIndex, 1);
    },


    onDrop(dropResult) {
      this.dashboards = applyDragForDndrop(this.dashboards, dropResult);
    },

    saveSettings() {
      this.loading = true;
      this.$api.homeDashboard.saveStandardDashboards({
        dashboards: this.dashboards,
      })
        .then((data) => {
          this.$emit('saved');
          this.show = false;
        })
        .finally(()=>{
          this.loading = false;
        })


    },


  }
}
</script>

<style lang="scss">
.widget-settings-dialog .el-tabs__content{
  overflow: unset;
}

.el-tabs__new-tab{
  width: 100px;

  &:after{
    content: 'Добавить';
  }
}

</style>