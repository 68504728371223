<template>
    <div class="builder-container-col">
        <div class="builder-container-row" v-for="element in elements">
            <builder-node
                v-if="showOneSelectedOnFirstLevel(element)"
                :element.sync="element"
                :relation-chain="element.relationChain ? element.relationChain : []"
                :load-children="loadChildren"
                @new-children="doRerender"
                @node-check-changed="$emit('node-check-changed', $event)"
                @add-field-from-node="$emit('add-field-from-node', $event)"
                @add-search-field-from-node="$emit('add-search-field-from-node', $event)"
            ></builder-node>

            <builder-column
                v-if="element.children && !!element.children.length"
                :level="level+1"
                :elements.sync="element.children"
                :load-children="loadChildren"
                @node-check-changed="$emit('node-check-changed', $event)"
                @add-field-from-node="$emit('add-field-from-node', $event)"
                @add-search-field-from-node="$emit('add-search-field-from-node', $event)"
            ></builder-column>
        </div>
    </div>

</template>

<script>
import BuilderNode from "./BuilderNode";
export default {
    name: "BuilderColumn",
    components: {BuilderNode},
    props: {
        elements: Array,
        level: {
            type: Number,
            default: 1
        },

        loadChildren: Function
    },
    beforeMount() {},

    methods: {
        doRerender(){
            this.$forceUpdate();
        },



        // На первом уровне показываем только один активный элемент,
        // т.к. любой отчет строится относительно одной главной сущности
        showOneSelectedOnFirstLevel(element){
            if( this.level != 1 ){
                return true;
            }

            let selectedElement = this.elements.find(el => el.checked);
            if( !selectedElement ){
                return true;
            }

            return selectedElement.key == element.key;
        }
    }
}
</script>
