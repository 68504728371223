<template>
  <el-row class="pagination" type="flex" justify="center" align="middle">
    <el-pagination
        v-if="paginator"
        v-show="!hidePageSelect"
        class="pagination__list"
        background
        layout="prev, pager, next"
        :page-count="paginator.pageCount"
        :current-page="+paginator.currentPage"
        @current-change="pageChanged"
    >
    </el-pagination>

    <div v-show="!hidePageSize" class="pagination__amount">
      <el-button
          :class="{ active: pageSize === paginator.perPage }"
          v-for="pageSize in paginator.perPageSizes"
          :key="pageSize"
          @click="setPerPage(pageSize)"
      >
        {{ pageSize }}
      </el-button>
    </div>
  </el-row>
</template>

<script>
export default {
  name: "paginator",
  props: {
    paginator: {type: Object},
    hidePageSelect: {type: Boolean, default: false},
    hidePageSize: {type: Boolean, default: false},
    useRouterQueryParam: {type: Boolean, default: false},
  },
  data() {
    return {}
  },
  beforeMount() {
    if( this.useRouterQueryParam ){
      if( this.$route.query.page ){
        this.paginator.page = this.$route.query.page;
      }
      if( this.$route.query['per-page'] ){
        this.paginator.perPage = this.$route.query['per-page'];
      }
    }
  },
  methods: {
    pageChanged(page) {
      this.setPaginatorPage(page);

      if( this.useRouterQueryParam ){
        this.$router.replace({path: this.$route.path, query: {...this.$route.query, 'per-page': this.paginator.perPage} }).catch(err => {});
        this.$router.replace({path: this.$route.path, query: {...this.$route.query, page: page}}).catch(err => {});
      }

      this.$emit('page-changed', page);
    },

    setPaginatorPage(page) {
      this.paginator.page = page;
      this.$emit('update:paginator', this.paginator);
    },
    setPerPage(perPage) {
      this.paginator.perPage = perPage;
      this.pageChanged(1);
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/css/project-variables";

.pagination {
  position: relative;
  margin: 32px 0 0;

  &__amount {
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    align-items: center;
    transform: translateY(-50%);

    .el-button.el-button--default {
      padding: 0;
      margin: 0 16px 0 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5;
      border: none;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        color: $--color-primary;
        font-weight: bold;
      }

      &:hover, &:focus {
        background-color: transparent;
        color: $--color-primary;
      }
    }
  }
}

</style>
