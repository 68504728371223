<template>
  <div class="scales-settings" v-loading="loading">

    <page-heading
      heading="Шкалы"
    >
      <div slot="right">
        <div class="el-row--flex">
          <library-scales-units-modal
            permission-see-units="setting.scales"
            permission-add-unit="setting.scales"
            permission-delete-unit="setting.scales"
            permission-edit-unit="setting.scales"
          >
            <el-button
              slot="reference"
              type="primary"
              size="small"
            >
              Редактирование единиц измерения
            </el-button>
          </library-scales-units-modal>

          <el-button
            style="margin-left: 20px"
            type="primary"
            size="small"
            icon="fas fa-plus"
            @click="createScaleTemplate"
          >
            Добавить шкалу
          </el-button>
        </div>
      </div>
    </page-heading>


    <el-row :gutter="40">
      <el-col
        :span="12"
        v-for="template in scaleTemplates"
        :key="template.id"
      >
        <el-card
          style="margin-bottom: 20px"
        >
          <div style="display: flex; flex-wrap: nowrap">
            <div
              class="scales-settings-visibility"
              @click="toggleTemplateVisibility(template)"
            >
              <i v-if="!scaleTemplatesVisibility[template.id]" class="fas fa-angle-down"></i>
              <i v-else class="fas fa-angle-up"></i>
            </div>
            <div style="flex: 1 1 auto; margin-right: 20px;">
              <click-to-edit
                v-model="template.name"
                @input="updateScaleTemplate(template)"
              ></click-to-edit>
            </div>

            <div>
              <el-popconfirm
                style="margin-right:10px;"
                confirm-button-text='Да'
                cancel-button-text='нет'
                icon="el-icon-question"
                :title="'Вы уверены, что хотите '+(template.active?'де':'')+'активировать шкалу?'"
                @confirm="changeTemplateActiveState(template)"
              >
                <el-switch
                  slot="reference"
                  style=""
                  :value="template.active"
                  size="mini"
                ></el-switch>
              </el-popconfirm>
              <el-popconfirm
                style="margin-right:10px;"
                confirm-button-text='Да'
                cancel-button-text='нет'
                icon="el-icon-question"
                title="Вы уверены, что хотите удалить шкалу?"
                @confirm="deleteScaleTemplate(template)"
              >
                <el-button
                  slot="reference"
                  class="table-action-button"
                  size="mini"
                  round
                  icon="far fa-trash-alt"
                ></el-button>
              </el-popconfirm>

              <el-button
                style="display: inline"
                class="table-action-button"
                size="mini"
                round
                icon="fas fa-plus"
                @click="addScopeToScaleTemplate(template)"
              ></el-button>
            </div>
          </div>

          <div
            v-show="scaleTemplatesVisibility[template.id]"
            style="margin-top: 20px;"
          >
            <div
              style="margin-bottom: 20px"
            >
              <el-input
                v-model="template.description"
                placeholder="Введите описание"
                type="textarea"
                :autosize="{ minRows: 2}"
                @change="updateScaleTemplate(template)"
              >
              </el-input>
            </div>




            <div>
              <el-row :gutter="40" style="margin-bottom: 5px">
                <el-col :span="6">Min, %</el-col>
                <el-col :span="6">Max, %</el-col>
                <el-col :span="10">Формула</el-col>
                <el-col :span="2"></el-col>
              </el-row>
            </div>
            <div
              v-for="scope in template.scopes"
            >
              <el-row :gutter="40" style="margin-bottom: 5px">
                <el-col :span="6"><el-input v-model="scope.min" size="mini" type="number" @change="updateScaleTemplate(template)"></el-input></el-col>
                <el-col :span="6"><el-input v-model="scope.max" size="mini" type="number" @change="updateScaleTemplate(template)"></el-input></el-col>
                <el-col :span="10"><el-input v-model="scope.formula" size="mini" @change="updateScaleTemplate(template)"></el-input></el-col>
                <el-col :span="2" align="right">
                  <el-popconfirm
                    confirm-button-text='Да'
                    cancel-button-text='нет'
                    icon="el-icon-question"
                    title="Вы уверены, что хотите удалить диапазон?"
                    @confirm="deleteScaleTemplateScope(template, scope)"
                  >
                    <el-button
                      slot="reference"
                      class="table-action-button"
                      size="mini"
                      round
                      icon="far fa-trash-alt"
                    ></el-button>
                  </el-popconfirm>
                </el-col>
              </el-row>

            </div>

          </div>
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import requestSender from "@/api/base/requestSender";
import ClickToEdit from "@/components/ClickToEdit.vue";
import PageHeading from "@/components/PageHeading.vue";
import LibraryScalesUnitsModal from "@/components/library/LibraryScalesUnitsModal.vue";

export default {
  name: "scales-settings",
  components: {LibraryScalesUnitsModal, PageHeading, ClickToEdit},
  props: {},
  watch: {},
  data() {
    return {
      loading: false,
      scaleTemplates: [],
      scaleTemplatesVisibility: {},
    }
  },
  mounted() {
    this.getScaleTemplates();

    let savedVisibilitySettings = localStorage.getItem('scaleTemplatesVisibility');
    if( savedVisibilitySettings ){
      savedVisibilitySettings = JSON.parse(savedVisibilitySettings);
      this.scaleTemplatesVisibility = savedVisibilitySettings;
    }
  },
  methods: {
    getScaleTemplates(){
      this.loading = true;
      requestSender('get', 'scale/templates-list', {
        sort: 'name',
        with_inactive: 1,
      })
        .then(data => {
          this.scaleTemplates = data.scales;
        })
        .finally(()=>{
          this.loading = false;
        })
    },

    createScaleTemplate(){
      this.loading = true;
      requestSender('post', 'scale/create', {})
        .finally(()=>{
          this.getScaleTemplates();
        })
    },

    updateScaleTemplate(template){
      this.loading = true;
      requestSender('post', 'scale/update', template)
        .then(data => {
          let templateIndex = this.scaleTemplates.findIndex(t => t.id===template.id);
          this.$set(this.scaleTemplates, templateIndex, data.scale);
        })
        .finally(()=>{
          this.loading = false;
        })
    },

    deleteScaleTemplate(template){
      this.loading = true;
      requestSender('post', 'scale/delete', {
        id: template.id,
      })
        .finally(()=>{
          delete this.scaleTemplatesVisibility[template.id];
          localStorage.setItem('scaleTemplatesVisibility', JSON.stringify(this.scaleTemplatesVisibility));

          this.getScaleTemplates();
        })
    },


    addScopeToScaleTemplate(template){
      template.scopes.push({});
      this.updateScaleTemplate(template);
    },
    deleteScaleTemplateScope(template, scope){
      template.scopes = template.scopes.filter(s => s.id !== scope.id);
      this.updateScaleTemplate(template);
    },


    toggleTemplateVisibility(template){
      this.$set(this.scaleTemplatesVisibility, template.id, !this.scaleTemplatesVisibility[template.id]);
      localStorage.setItem('scaleTemplatesVisibility', JSON.stringify(this.scaleTemplatesVisibility));
    },

    changeTemplateActiveState(template){
      template.active = !template.active;
      this.updateScaleTemplate(template)
    },
  }
}
</script>


<style lang="scss" scoped>
.scales-settings-visibility{
  padding: 5px 10px 5px 0;
  cursor: pointer;
}
</style>