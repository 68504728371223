<template>
  <el-dialog
    :title="'Настройки колонки'"
    :close-on-click-modal="false"
    append-to-body
    :visible.sync="show"
    width="660px"
  >

    <el-card shadow="never">
      <div style="display: flex;justify-content: space-between">
        <div>
          Сортировка

          <el-popover
            placement="left"
            width="500"
            trigger="click"
          >
            <div>
              По умолчанию конструктор отчетов сортирует данные по колонке ID той сущности, которую вы выбрали, как основную в отчете.<br><br>
              Вы можете указать данную колонку для сортировки<br><br>
              Если выбрать несколько колонок, то сортировка будет применяться в такой же последовательности, в какой они стоят в зоне выбора колонок
            </div>

            <el-button
              slot="reference"
              style="padding: 0; margin-left: 10px"
              size="mini"
              type="text"
              icon="fas fa-question"
            >
            </el-button>
          </el-popover>
        </div>
        <el-radio-group v-model="selectedField.orderBy" size="mini">
          <el-radio-button :label="null">Нет</el-radio-button>
          <el-radio-button label="asc">ASC</el-radio-button>
          <el-radio-button label="desc">DESC</el-radio-button>
        </el-radio-group>
      </div>


    </el-card>

    <el-card shadow="never" style="margin-top: 20px">
      <div  style="display: flex;justify-content: space-between">
        <div>
          Группировка

          <el-popover
            placement="left"
            width="500"
            trigger="click"
          >
            <div>
              Группировка по колонкам позволяет объединять данные по общим признакам, рассчитывать суммы, средние значения и другие агрегированные показатели для удобного анализа.<br><br>
              Если вы выбираете колонку для группировки, то система найдет все записи с одинаковыми значениями в данной колонке. Далее вы сможете настроить другую колонку, как агрецию по такой группе.<br><br>
              Если группировать несколько колонок, данные будут сгруппированы по их сочетанию, создавая более детализированное представление отчета.
            </div>

            <el-button
              slot="reference"
              style="padding: 0; margin-left: 10px"
              size="mini"
              type="text"
              icon="fas fa-question"
            >
            </el-button>
          </el-popover>

        </div>
        <div><el-switch v-model="selectedField.groupBy"></el-switch></div>
      </div>


    </el-card>

    <el-card shadow="never" style="margin-top: 20px">
      <div style="display: flex;justify-content: space-between">
        <div>
          Агрегация

          <el-popover
            placement="left"
            width="500"
            trigger="click"
          >
            <div>
              Агрегация позволяет вычислять суммарные, средние, минимальные, максимальные и количественные значения для сгруппированных данных.<br><br>
              Если агрегировать несколько колонок, каждая из них будет применяться к соответствующим данным, помогая получить более полную картину отчета.<br><br>
              SUM - Сумма всех элементов в группе. Может применяться только к числовым данным.<br>
              AVG - Среднее значение всех элементов в группе. Может применяться только к числовым данным.<br>
              MIN - Минимальное значение всех элементов в группе. Может применяться как к числовым, так и к строковым данным.<br>
              MAX - Максимальное значение всех элементов в группе. Может применяться как к числовым, так и к строковым данным.<br>
              CNT - Количество элементов в группе.<br>
              CONCAT - Все элементы, попавшие в группу, будут объединены в одну строку через точку с запятой.<br>
              ANY - Будет выбран случайный элемент из группы. Удобно, когда в группе одинаковые значения, но есть необходимость его вывести в качестве колонки.<br>
            </div>

            <el-button
              slot="reference"
              style="padding: 0; margin-left: 10px"
              size="mini"
              type="text"
              icon="fas fa-question"
            >
            </el-button>
          </el-popover>

        </div>
        <div>
          <el-radio-group v-model="selectedField.aggregation" size="mini">
            <el-radio-button :label="null">Нет</el-radio-button>
            <el-radio-button label="SUM">SUM</el-radio-button>
            <el-radio-button label="AVG">AVG</el-radio-button>
            <el-radio-button label="MIN">MIN</el-radio-button>
            <el-radio-button label="MAX">MAX</el-radio-button>
            <el-radio-button label="COUNT">CNT</el-radio-button>
            <el-radio-button label="CONCAT">CONCAT</el-radio-button>
            <el-radio-button label="ANY">ANY</el-radio-button>
          </el-radio-group>
        </div>
      </div>


    </el-card>





    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="show = false;">Закрыть</el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  name: 'SelectedFieldSettingsDialog',
  props: {},
  data() {
    return {
      show: false,

      selectedField: {},
    }
  },

  methods: {
    open(selectedField){
      this.selectedField = selectedField;
      this.show = true;
    },


  }
}
</script>